import { useState } from 'react';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';
import superagent from "superagent";

const API_BASE = process.env.REACT_APP_API_URL;
const LOGIN_URL = "/api/authentication/login"

const LoginForm = ({onToken, ...rest}) => {
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [resp, setResp] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setError(null);
    setIsProcessing(true);

    let resp = null;
    // Get PaymentIntent
    try {
      resp = await superagent.post(`${API_BASE}${LOGIN_URL}`).send({ email, password, device_name: "web_app" }) // sends a JSON post body      ;
    } catch (err) {
      setError({ message: "Failed to login!"});
      return;
    } finally {
      setIsProcessing(false);
    }

    setResp(resp.body);
    onToken(resp.body.data.token);
  };

  const reset = () => {
    setError(null);
    setResp(null);
    setIsProcessing(false);
    onToken(null);
  };

  return resp ?
      (<div className="mt-5" {...rest}>
        <h3 className="font-display block text-xl font-extrabold text-gray-900 tracking-tight border-gray-300">Login Successful</h3>
        {resp && <p className="font-body"><code>{resp.data.token}</code></p>}
        <div>
          <Button className="mt-5 ml-auto block" onClick={reset}>Reset</Button>
        </div>
      </div>)

      : (<form onSubmit={handleSubmit} {...rest}>

        <label className="font-body">Email <input className="shadow rounded py-2 px-5 mx-2" type="email" value={email} onChange={e=> setEmail(e.currentTarget.value)} /></label>
        <label className="font-body">Password <input className="shadow rounded  py-2 px-5 mx-2" type="password" value={password} onChange={e=> setPassword(e.currentTarget.value)} /></label>
        {error && <ErrorMessage className="mt-2">{error.message}</ErrorMessage>}

        <div>
          <Button type="submit" disabled={isProcessing} className="mt-5 ml-auto block">{!isProcessing ? "Login" : (<span className="animate-spin block">🤔</span>)}</Button>
        </div>
      </form>)
};

export default LoginForm;