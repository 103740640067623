import classnames from "classnames";
import Card from "components/Card";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51KLn1dBQg4NzxVLWIPAcaF5CZ1i9932C8IqtAZYStNTqoWiKIACDA2BxArMTUcwVXT2GNtiusPVvWbUJ6Rig11On00oAv7sDTZ');

const PaymentCard = ({className = "", title = "", children, ...rest}) => {
  return (
    <Card className={classnames("", className)} {...rest}>
      <h2 className="font-display block text-2xl font-extrabold text-gray-900 tracking-tight pb-2 border-b-2 border-gray-300">{title}</h2>
      <Elements stripe={stripePromise}>
        {children}
      </Elements>
    </Card>
  );
};

export default PaymentCard;