import classnames from "classnames";

const Card = ({className = "", children, ...rest}) => {
  return (
    <div className={classnames("rounded-xl shadow-2xl p-5", className)} {...rest}>
      {children}
    </div>
  );
};

export default Card;