import { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';
import superagent from "superagent";

const API_BASE = process.env.REACT_APP_API_URL;
const SETUP_INTENT_URL = "/api/setup_intents";

const MinimalCardForm = ({token, ...rest}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [setupIntentResp, setSetupIntentResponse] = useState(null);

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setError(null);
    setIsProcessing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setIsProcessing(false);
      return;
    }

    let setupIntent = null;
    // Get PaymentIntent
    try {
      const { body } = await superagent.post(`${API_BASE}${SETUP_INTENT_URL}`)
          .send()
          .set('authorization', `Bearer ${token}`);

      setupIntent = body.data.attributes.client_secret;
    } catch (err) {
      console.log(err)
      setError({ message: "Failed to get Setup Intent!"});
      setIsProcessing(false);
      return;
    }

    console.log(setupIntent);

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Confirm the card payment
    const {error, setupIntent: setupIntentResp} = await stripe.confirmCardSetup(setupIntent, {
      payment_method: {
        card: cardElement
      }
    });

    if (error) {
      console.log('[error]', error);
      setIsProcessing(false);
      setError(error);
    } else {
      console.log('[PaymentIntent]', setupIntentResp);
      setIsProcessing(false);
      setSetupIntentResponse(setupIntentResp.payment_method);
    }
  };

  const reset = () => {
    setError(null);
    setSetupIntentResponse(null);
    setIsProcessing(false);
  };

  return setupIntentResp ?
      (<div className="mt-5">
        <h3 className="font-display block text-xl font-extrabold text-gray-900 tracking-tight border-gray-300">Success</h3>
        <p className="font-body">Payment method: <code>{setupIntentResp}</code></p>
        <div>
          <Button className="mt-5 ml-auto block" onClick={reset}>Reset</Button>
        </div>
      </div>)

      : (<form onSubmit={handleSubmit} {...rest}>
        <CardElement
            className="shadow-md p-5 rounded"
            onChange={(e) => {
              setError(e.error);
            }}/>
        {error && <ErrorMessage className="mt-2">{error.message}</ErrorMessage>}

        <div>
          <Button type="submit" disabled={!stripe || isProcessing} className="mt-5 ml-auto block">{!isProcessing ? "Add payment method" : (<span className="animate-spin block">🤑</span>)}</Button>
        </div>
      </form>)
};

export default MinimalCardForm;