import LoginForm from "components/LoginForm";
import MinimalCardForm from "components/MinimalCardForm";
import PaymentCard from "components/PaymentCard";
import { useState } from "react";

function App() {
  const [token, setToken] = useState(null);
  return (
    <div className="space-y-20 sm:space-y-32 md:space-y-40 lg:space-y-44 overflow-hidden">
      <header className="relative max-w-screen-lg xl:max-w-screen-xl mx-auto p-5">
        <h1 className="font-display inline-block text-3xl font-extrabold text-gray-900 tracking-tight">Inuous Stripe Debugger</h1>
      </header>
      <section className="relative max-w-screen-lg xl:max-w-screen-xl mx-auto p-5">
        <PaymentCard className="max-w-2xl mx-auto mb-12" title="Login to Inuous">
          <LoginForm className="mt-5" onToken={setToken} />
        </PaymentCard>
        {token &&
        <PaymentCard className="max-w-2xl mx-auto" title="Add payment method">
          <p className="font-body text-gray-900 text-sm mt-2">Try <code>4242424242424242</code></p>
          <MinimalCardForm className="mt-5" token={token} />
        </PaymentCard>
        }
      </section>
      <footer className="bg-gray-50 pt-16 pb-12 sm:pt-20 md:pt-24 xl:pt-32 sm:pb-20 p-5">

      </footer>
    </div>
  );
}

export default App;
