import classnames from "classnames";

const Button = ({className = "", children, ...rest}) => {

  return (
    <button className={classnames("w-full sm:w-auto flex-none bg-gray-900 hover:bg-gray-700 text-white text-lg leading-6 font-display font-semibold py-3 px-6 border border-transparent rounded-xl focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200", className)} {...rest}>
      {children}
    </button>
  )
};

export default Button;